// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

// other
import './plugins/flickity.ios13fix.js';

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { loadComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'


import ScrollService, { scrollToElement } from './services/Scroll'

function setScrollPosition() {
    const id = window.location.hash.replace('#', '')
    const anchor = document.getElementById(id)

    if (id && anchor) {
        scrollToElement(anchor)
    }
}

window.addEventListener('load', () => {
    setTimeout(() => {
        setScrollPosition()
    }, 10);
})